import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { Col, Container, Navbar, Row, Button, ListGroup } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ShareButtons from "../components/shareButtons"
import AdBanner from "../components/adBanner"
import Contact from "../components/contact"

export default function PageDetail({ pageContext, location, data }) {
  const page = data.markdownRemark
  const { hospitalPackages } = pageContext

  function fullTitle() {
    return page.frontmatter.name
  }

  function seoTitle() {
    return page.frontmatter.name + " - Contact, Directions, Visiting hours"
  }

  function renderOperationHours() {
    if (!page.frontmatter.operation_hours) return
    return (
      <Col xs={12}>
        <h5>Operating Hours</h5>
        <ListGroup>
          {page.frontmatter.operation_hours.map(item => {
            return <ListGroup.Item variant="info">{item}</ListGroup.Item>
          })}
        </ListGroup>
      </Col>
    )
  }

  function renderOtherHospitals() {
    if (data.allMarkdownRemark.totalCount <= 0) return
    return (
      <Col xs={12} className="mb-5">
        <h5 className="text-capitalize">
          Other {page.frontmatter.hospital_group.replace(/-/g, " ")}
        </h5>
        <ListGroup as="ol" numbered>
          {data.allMarkdownRemark.edges.map(({ node }) => {
            return (
              <ListGroup.Item
                as="li"
                key={node.id}
                className="d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">
                    <Link to={node.fields.slug}>{node.frontmatter.name}</Link>
                  </div>
                  {node.frontmatter.city}, {node.frontmatter.state}
                </div>
                <div>
                  <Button
                    variant="info"
                    href={node.frontmatter.contact.waze_link}
                    target="_blank"
                    size="xs"
                  >
                    <img alt="" src="/icon_waze.png" />
                  </Button>
                  <Button
                    variant="light"
                    href={node.frontmatter.contact.gmap_link}
                    target="_blank"
                    size="xs"
                  >
                    <img alt="" src="/icon_gmap.png" />
                  </Button>
                </div>
              </ListGroup.Item>
            )
          })}
        </ListGroup>
      </Col>
    )
  }

  function renderHospitalPackages() {
    if (hospitalPackages.length <= 0) return
    return (
      <Col xs={12} className="mb-5">
        <h5 className="text-capitalize">
          Medical Check Up Packages in {page.frontmatter.name}
        </h5>
        <ListGroup as="ol" numbered>
          {hospitalPackages.map(({ node }) => {
            return (
              <ListGroup.Item
                as="li"
                key={node.id}
                className="d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">
                    <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
                  </div>
                  {node.frontmatter.description}
                </div>
                <div className="d-flex justify-content-between">
                  <p className="h5 align-self-end">
                    {node.frontmatter.price === 0
                      ? "Free"
                      : node.frontmatter.price.toLocaleString("en-MY", {
                          style: "currency",
                          currency: "MYR",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                  </p>
                </div>
              </ListGroup.Item>
            )
          })}
        </ListGroup>
      </Col>
    )
  }

  const url = data.site.siteMetadata.siteUrl + location.pathname
  const twitterHandle = ""
  const tags = ""

  return (
    <Layout>
      <SEO title={seoTitle()} description={page.frontmatter.description} />
      <Container>
        <Row>
          <Col>
            <Row>
              <Col xs={12}>
                <h3>{fullTitle()}</h3>
                <p>{page.frontmatter.description}</p>
              </Col>
            </Row>
            <hr />
            <Row className="mb-5">
              <Col xs={12}>
                <h5>Visiting Hours</h5>
                <ListGroup>
                  {page.frontmatter.visiting_hours.map(item => {
                    return (
                      <ListGroup.Item variant="info">{item}</ListGroup.Item>
                    )
                  })}
                </ListGroup>
                <ListGroup className="mt-3" variant="info">
                  {page.frontmatter.visiting_rules.map(item => {
                    return (
                      <ListGroup.Item variant="warning">{item}</ListGroup.Item>
                    )
                  })}
                </ListGroup>
              </Col>
            </Row>
            <Row>{renderOperationHours()}</Row>
            <Row className="justify-content-center">
              <AdBanner />
            </Row>
            <Row>
              <Col xs={12} className="mb-5">
                <h5>Location</h5>
                <Button
                  variant="info"
                  href={page.frontmatter.contact.waze_link}
                  target="_blank"
                  size="lg"
                  className="mr-3"
                >
                  <img alt="" src="/icon_waze.png" />
                </Button>
                <Button
                  variant="light"
                  href={page.frontmatter.contact.gmap_link}
                  target="_blank"
                  size="lg"
                >
                  <img alt="" src="/icon_gmap.png" />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="mb-5">
                <h5>Contact Information</h5>
                <Contact contact={page.frontmatter.contact} />
              </Col>
            </Row>
            <Row>{renderHospitalPackages()}</Row>
            <Row>{renderOtherHospitals()}</Row>
            <Row className="mb-3">
              <Col xs={12}>
                <div dangerouslySetInnerHTML={{ __html: page.html }} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Navbar fixed="bottom" className="justify-content-center bg-dark">
              <h5 className="text-muted mr-sm-2">Share </h5>
              <div className="mr-auto">
                <ShareButtons
                  title={fullTitle()}
                  url={url}
                  twitterHandle={twitterHandle}
                  tags={tags}
                />
              </div>
              <Button variant="outline-primary" className="mr-sm-2" href="/">
                Home
              </Button>
            </Navbar>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $hospitalgroup: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        name
        hospital_group
        description
        contact {
          telephone
          whatsapp
          emergency
          email
          fax
          address
          waze_link
          gmap_link
        }
        operation_hours
        visiting_hours
        visiting_rules
        date(formatString: "DD MMMM, YYYY")
      }
      excerpt
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { hospital_group: { eq: $hospitalgroup } }
        fields: { slug: { ne: $slug } }
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            name
            state
            city
            contact {
              waze_link
              gmap_link
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
